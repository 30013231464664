<template>
  <div class="my-publish-container">
    <Title name="我的竞买"></Title>
    <div class="filter-content">
      <el-form inline label-width="83px">
        <el-form-item label="发布时间:">
          <el-date-picker style="width:150px" size="mini" v-model="filterForm.fromDate" type="date" value-format="yyyy-MM-dd" placeholder="选择开始日期"> </el-date-picker>
          <span style="font-size:12px"> 至 </span>
          <el-date-picker size="mini" style="width:150px" v-model="filterForm.toDate" type="date" value-format="yyyy-MM-dd" placeholder="选择结束日期"> </el-date-picker>
        </el-form-item>
        <el-form-item label="状态:">
          <el-select v-model="filterForm.apprvStatus" clearable placeholder="请选择产品类型" size="mini" style="width:150px">
            <el-option v-for="item in statusType" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button size="small" type="primary" @click="getProductList" style="margin-left:10px;padding:7px 10px;font-size:12px">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tabel-content">
      <el-table :header-cell-style="{background: '#f9faff', 'font-weight': 700, color: '#666', padding: '12 5px','text-align':'center'}"     :cell-style="{'text-align':'center'}" :data="tableData" style="width: 100%">
        <el-table-column prop="addTime" label="发布时间" width="180"> </el-table-column>
        <el-table-column prop="productName" label="产品名称" width="180"> </el-table-column>
        <el-table-column prop="createUserName" width="180" label="发布人"> </el-table-column>
        <el-table-column prop="reqNum,unit" label="需求量"> 
         <template  slot-scope="scope">
              {{ scope.row.reqNum }}&nbsp;({{scope.row.unit}})
         </template>
        </el-table-column>
        <el-table-column prop="maxPrice" width="100" label="最高竞买价"> </el-table-column>
        <el-table-column prop="apprvStatus" label="审核状态">
          <template slot-scope="scope">
           <div   :class="(scope.row.apprvStatus=='F')?'failText':'successText'">
      {{scope.row.apprvStatus | getApprvStatus}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="startTime" width="180" label="开始时间"> </el-table-column>
        <el-table-column prop="validDate" width="180" label="到期时间"> </el-table-column>
        <el-table-column prop="apprvTime" width="180" label="审核时间"> </el-table-column>
        
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.apprvStatus === 'P' && scope.row.isMake !== 'Y' && new Date(scope.row.startTime) > new Date() && buyExamine" size="small" type="text" @click="onWithdraw(scope.row.id)"
              >撤回</el-button
            >
            <el-button v-if="(scope.row.apprvStatus === 'R' || scope.row.apprvStatus === 'F' || scope.row.apprvStatus === 'N') && clientRePush " size="small" type="text" @click="toEdit(scope.row.id,scope.row)">修改</el-button>
            <el-button v-if="scope.row.apprvStatus === 'A'&& buyExamine " size="small" type="text" @click="toExamine(scope.row.id,scope.row)">审核</el-button>       
             <div>
            <el-button v-if="scope.row.apprvStatus === 'P'  || scope.row.apprvStatus === 'A'|| scope.row.apprvStatus === 'F' || scope.row.apprvStatus === 'R'" size="small" type="text" @click="toNewDetail(scope.row.id,scope.row)">查看详情</el-button>
             </div>
             <div>
            <el-button v-if="scope.row.apprvStatus === 'P'" size="small" type="text" @click="toDetail(scope.row.id)">查看报价</el-button>
             </div> 
            <!-- <el-button v-if="scope.row.apprvStatus === 'A'" size="small" type="text" @click="showAuditDialog(scope.row.id)">审核</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination" v-if="pageManage.total">
        <el-pagination background layout="prev, pager, next" :total="pageManage.total" :page-size="pageManage.limit" :current-page="pageManage.page" @current-change="changeCurrentPage">
        </el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="approveDialogVisible" title="竞买审核" @close="approveDialogVisible = false">
      <el-form ref="approveForm" :model="approveForm" status-icon label-position="left" label-width="100px" style="width: 400px; margin-left:50px;">
        <el-form-item label="是否通过：">
          <el-radio-group v-model="approveForm.apprvStatus">
            <el-radio label="P">通过</el-radio>
            <el-radio label="F">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审核备注：" prop="traceMsg">
          <el-input v-model="approveForm.remark" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="approveDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmApprove">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {proProductList, withdrawProduct, auditOrFaudit,myAuthority} from '../../api';
import Title from '../../component/Title';
export default {
  components: {
    Title
  },
  data() {
    return {
      clientRePush:false,
      buyExamine:false,
      approveDialogVisible: false,
      approveForm: {
        id: undefined,
        apprvStatus: 'P',
        remark: ''
      },
      statusType: [
        {value: 'A', label: '待审核'},
        {value: 'P', label: '已通过'},
        {value: 'F', label: '已拒绝'}
      ],
      productPictureManage: {
        dialogImageUrl: '',
        dialogVisible: false
      },
      filterForm: {
        type: 1,
        fromDate: '',
        toDate: '',
        apprvStatus: ''
      },
      pageManage: {
        limit: 10,
        page: 1,
        total: 0
      },
      tableData: []
    };
  },
  created() {
    this.$store.commit('changeMenuType', 'BUY');
    this.getProductList();

    if (localStorage.getItem('userPermission').indexOf('client:re:push') !==-1) {
       this.clientRePush=true
       console.log('clientRePush',this.clientRePush);
      }
    if (localStorage.getItem('userPermission').indexOf('buy:examine') !==-1) {
       this.buyExamine=true
       console.log('buyExamine',this.buyExamine);
      }
  },
  filters: {
    getApprvStatus(val) {
      switch (val) {
        case 'A':
          return '待审核';
          break;
        case 'P':
          return '审核通过';
          break;
        case 'F':
          return '审核未通过';
          break;
        case 'N':
          return '未提交';
          break;
        case 'R':
          return '已撤回';
          break;
      }
    }
  },
  computed: {
    chehui: function() {
      this.tableData.forEach(item => {
        return new Date(item.startTime) < new Date() ? true : false;
      })``;
    }
  },
  methods: {
    showAuditDialog(id) {
      this.approveForm.id = id;
      this.approveForm.apprvStatus = 'P';
      this.approveForm.remark = '';
      this.approveDialogVisible = true;
    },
    confirmApprove() {
      console.log(this.approveForm);
      auditOrFaudit(this.approveForm).then(res => {
        this.approveDialogVisible = false;
        if (res.errno == 0) {
          this.$message.success('操作成功');
        }
        this.getProductList();
      });
    },
    onWithdraw(id) {
      this.$confirm('此操作将撤回竞买信息, 是否继续?', '立即发布', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        withdrawProduct({id}).then(res => {
          if (res.errno === 0) {
            this.$message({
              type: 'success',
              message: '撤回成功！'
            });
            this.getProductList();
          }
        });
      });
    },
    toEdit(id,row) {
         if (row.approveRemarks =='' || row.approveRemarks==undefined ) {
      this.approveRemarksShow=false
      }else{
       this.approveRemarksShow=true
      }
      this.$router.push({
        path: '/User/Publish',
        query: {
          id,
          isEdit: true
        }
      });
    },
   //审核
    toExamine (id,row) {
      console.log('row',row);
      if (row.approveRemarks =='' || row.approveRemarks==undefined) {
      this.approveRemarksShow=false
      }else{
       this.approveRemarksShow=true
      }
      this.$router.push({
        path: '/User/Publish',
        query: {
          id,
          isEdit: true,
          isExamine:true,
          isRemarksShow:this.approveRemarksShow
        }
      })
    },
       //查看详情 
    toNewDetail(id,row){

      if (row.approveRemarks =='' || row.approveRemarks==undefined ||  row.apprvStatus=='A') {
      this.approveRemarksShow=false
      }else{
       this.approveRemarksShow=true
      }
        this.$router.push({
        path: '/User/Publish',
        query: {
          id,
          isEdit: true,
          isExamine:true,
          isHistory:true,
          isRemarksShow:this.approveRemarksShow
        }
      })
    },
    toDetail(id, unit) {
      this.$router.push({
        path: '/User/PublishDetail',
        query: {
          id: id,
          unit: unit,
          isBuy: true
        }
      });
    },

    getProductList() {
      proProductList({
        ...this.filterForm,
        ...this.pageManage
      }).then(res => {
        this.tableData = res.data.repProductList;
        this.pageManage.total = res.data.total;
      });
    },
    changeCurrentPage(num) {
      this.pageManage.page = num;
      this.getProductList();
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-button+.el-button{
  margin-left:0px;
}
.my-publish-container {
  background: #fff;

  .pagination {
    padding: 20px;
    box-sizing: border-box;
    text-align: right;
  }
}

.filter-content {
  line-height: 40px;
  margin-top: 12px;
  padding: 10px;
}
.tabel-content {
  padding: 0 10px;
}
.successText{
  color: #000;
}
.failText{
  color: #0a83f1;
}

/deep/.el-form-item__label {
  font-size: 12px;
}

/deep/.el-table td,
.el-table th {
  padding: 6px 0;
}
</style>
